@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  background-color: #f8fffe;
  color: #2f3a39;
  display: flex;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
  height: 100vh; /* 전체 뷰포트 높이 사용 */
  margin: 0; /* 기본 margin 제거 */

  font-family: -apple-system, "Montserrat", "Pretendard", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.3px;
}

.content-wrapper {
  width: fit-content;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 (선택 사항) */
}

.current-time {
  font-weight: 600;
  font-size: 150%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 (선택 사항) */
  margin-bottom: 36px;
}

p {
  width: fit-content;
}

.time-block {
  font-weight: 500;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 (선택 사항) */
  margin-bottom: 2em;
}

.percent-block {
  margin-bottom: 10px;
  font-size: 22px;
}
.percent-block-caption {
  margin-bottom: 4px;
  font-size: 13px;
}
.percent-block-small {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

progress {
  -webkit-appearance: none; /* Safari 및 Chrome에서 기본 스타일 제거 */
  appearance: none; /* 기본 스타일 제거 */
  width: 300px; /* 너비 100% */
  height: 20px; /* 높이 설정 */
  border-radius: 10px; /* 모서리 둥글게 */
}
.progress-bar::-webkit-progress-bar {
  background-color: #d7eeec; /* progress 바의 배경색 */
  border-radius: 10px; /* 모서리 둥글게 */
}

.progress-bar::-webkit-progress-value {
  background-color: #34dbcd; /* 진행 중인 부분의 색상 */
  border-radius: 10px; /* 모서리 둥글게 */
}

.progress-bar::-moz-progress-bar {
  background-color: #34dbcd; /* Firefox에서 진행 중인 부분의 색상 */
  border-radius: 10px; /* 모서리 둥글게 */
}

.nametag {
  font-weight: 400;
}
a {
  padding: 2px;
  font-weight: 600;
  color: #34dbcd;
  text-decoration: none;
  border: 5px solid #3498db00;
  border-radius: 10px;
  transition: color 0.3s ease, background-color 0.3s ease; /* 이징 효과 추가 */
}

a:hover {
  background-color: #34dbcda7; /* 호버 시 배경색 */
  color: #161616; /* 호버 시 텍스트 색상 변경 */
  border: 5px solid #3498db00; /* 둥근 보더 추가 */
  border-radius: 10px; /* 둥근 모서리 */
}

.new-year-message {
  font-size: 30px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 (선택 사항) */
}

.new-year-message h1 {
  margin-bottom: 10px;
}
.new-year-message-sub {
  align-self: center;
  font-size: 15px;
  margin-top: 3px;
  margin-bottom: 3px;
}
